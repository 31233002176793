import React from 'react'

const PopularDestination = () => {
  return (
    <>
    <section class="layout-pb-md">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Top destinations</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">These popular destinations have a lot to offer</p>
            </div>
          </div>
        </div>

        <div class="row y-gap-40 justify-between pt-40 sm:pt-20">

          <div data-anim-child="slide-up delay-3" class="col-xl-3 col-md-4 col-sm-6 is-in-view">

            <a href="#" class="citiesCard -type-3 d-block rounded-4 ">
              <div class="citiesCard__image ratio ratio-1:1">
              <img class="img-ratio js-lazy loaded" src="assets/img/populardestinations/1.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="citiesCard__content px-30 py-30">
                <h4 class="text-26 fw-600 text-white">Los Angeles</h4>
                <div class="text-15 text-white">1,714 properties</div>
              </div>
            </a>

          </div>

          <div data-anim-child="slide-up delay-4" class="col-xl-6 col-md-4 col-sm-6 is-in-view">

            <a href="#" class="citiesCard -type-3 d-block rounded-4 h-full">
              <div class="citiesCard__image ">
              <img class="img-ratio js-lazy loaded" src="assets/img/populardestinations/2.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="citiesCard__content px-30 py-30">
                <h4 class="text-26 fw-600 text-white">London</h4>
                <div class="text-15 text-white">1,714 properties</div>
              </div>
            </a>

          </div>

          <div data-anim-child="slide-up delay-5" class="col-xl-3 col-md-4 col-sm-6 is-in-view">

            <a href="#" class="citiesCard -type-3 d-block rounded-4 ">
              <div class="citiesCard__image ratio ratio-1:1">
                <img class="img-ratio js-lazy loaded" src="assets/img/populardestinations/3.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="citiesCard__content px-30 py-30">
                <h4 class="text-26 fw-600 text-white">Reykjavik</h4>
                <div class="text-15 text-white">1,714 properties</div>
              </div>
            </a>

          </div>

          <div data-anim-child="slide-up delay-6" class="col-xl-6 col-md-4 col-sm-6 is-in-view">

            <a href="#" class="citiesCard -type-3 d-block rounded-4 h-full">
              <div class="citiesCard__image ">
                <img class="img-ratio js-lazy loaded" src="assets/img/populardestinations/4.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="citiesCard__content px-30 py-30">
                <h4 class="text-26 fw-600 text-white">Paris</h4>
                <div class="text-15 text-white">1,714 properties</div>
              </div>
            </a>

          </div>

          <div data-anim-child="slide-up delay-7" class="col-xl-3 col-md-4 col-sm-6 is-in-view">

            <a href="#" class="citiesCard -type-3 d-block rounded-4 ">
              <div class="citiesCard__image ratio ratio-1:1">
              <img class="img-ratio js-lazy loaded" src="assets/img/populardestinations/5.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="citiesCard__content px-30 py-30">
                <h4 class="text-26 fw-600 text-white">Amsterdam</h4>
                <div class="text-15 text-white">1,714 properties</div>
              </div>
            </a>

          </div>

          <div data-anim-child="slide-up delay-8" class="col-xl-3 col-md-4 col-sm-6 is-in-view">

            <a href="#" class="citiesCard -type-3 d-block rounded-4 ">
              <div class="citiesCard__image ratio ratio-1:1">
              <img class="img-ratio js-lazy loaded" src="assets/img/populardestinations/6.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="citiesCard__content px-30 py-30">
                <h4 class="text-26 fw-600 text-white">Istanbul</h4>
                <div class="text-15 text-white">1,714 properties</div>
              </div>
            </a>

          </div>

        </div>
      </div>
    </section>
    
    </>
  )
}

export default PopularDestination