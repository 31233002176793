import React from 'react'

const HeroDiscount = () => {
  return (
    <>
     <section class="layout-pt-md layout-pb-md">
      <div class="container">
        <div class="row y-gap-20">
          <div data-anim="slide-up" class="col-md-6">

            <div class="ctaCard -type-1 rounded-4 ">
              <div class="ctaCard__image ratio ratio-63:55">
                <img class="img-ratio js-lazy" src="assets/img/backgrounds/1.png" alt="image"/>
              </div>

              <div class="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">


                <h4 class="text-40 lg:text-26 text-white">Things to do on<br/> your trip</h4>

                <div class="d-inline-block mt-30">
                  <a href="#" class="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">Experiences</a>
                </div>
              </div>
            </div>

          </div>

          <div data-anim="slide-up delay-1" class="col-md-6">

            <div class="ctaCard -type-1 rounded-4 ">
              <div class="ctaCard__image ratio ratio-63:55">
                <img class="img-ratio js-lazy" src="assets/img/backgrounds/5.png" alt="image"/>
              </div>

              <div class="ctaCard__content py-70 px-70 lg:py-30 lg:px-30">

                <div class="text-15 fw-500 text-white mb-10">Enjoy Summer Deals</div>


                <h4 class="text-40 lg:text-26 text-white">Up to 70% Discount!</h4>

                <div class="d-inline-block mt-30">
                  <a href="#" class="button px-48 py-15 -blue-1 -min-180 bg-white text-dark-1">Learn More</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    
    </>
  )
}

export default HeroDiscount