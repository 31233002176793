import React from 'react'

const Testimonial = () => {
  return (
    <>
    <section class="layout-pt-lg layout-pb-lg bg-dark-3">
      <div class="container">
        <div class="row y-gap-60">
          <div class="col-xl-5 col-lg-6">
            <h2 class="text-30 text-white">What our customers are<br/> saying us?</h2>
            <p class="text-white mt-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo.</p>

            <div class="row y-gap-30 text-white pt-60 lg:pt-40">
              <div class="col-sm-5 col-6">
                <div class="text-30 lh-15 fw-600">13m+</div>
                <div class="lh-15">Happy People</div>
              </div>

              <div class="col-sm-5 col-6">
                <div class="text-30 lh-15 fw-600">4.88</div>
                <div class="lh-15">Overall rating</div>

                <div class="d-flex x-gap-5 items-center pt-10">

                  <div class="icon-star text-white text-10"></div>

                  <div class="icon-star text-white text-10"></div>

                  <div class="icon-star text-white text-10"></div>

                  <div class="icon-star text-white text-10"></div>

                  <div class="icon-star text-white text-10"></div>

                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 offset-xl-2 col-lg-5 offset-lg-1 col-md-10">


            <div class="testimonials-slider-2 js-testimonials-slider-2 swiper-cards swiper-3d swiper-initialized swiper-horizontal swiper-pointer-events swiper-watch-progress">
              <div class="swiper-wrapper" id="swiper-wrapper-d2d0ff75bc10954b2" aria-live="polite"  style={{opacity: "1", transitionDuration: "0ms"}}>

                <div class="swiper-slide" role="group" aria-label="1 / 3" style={{width:" 410px", zIndex: "1", transform: "translate3d(calc(-13% + 0px), 0px, -200px) rotateZ(-4deg) scale(1)", transitionDuration: "0ms"}}>
                  <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40 shadow-2">
                    <div class="">
                      <h4 class="text-16 fw-500 text-blue-1 mb-20">Hotel Equatorial Melaka</h4>
                      <p class="testimonials__text lh-18 fw-500 text-dark-1">"Our family was traveling via bullet train between cities in Japan with our luggage - the location for this hotel made that so easy. Agoda price was fantastic."</p>

                      <div class="pt-20 mt-28 border-top-light">
                        <div class="row x-gap-20 y-gap-20 items-center">
                          <div class="col-auto">
                            <img src="assets/img/avatars/1.png" alt="image"/>
                          </div>

                          <div class="col-auto">
                            <div class="text-15 fw-500 lh-14">Courtney Henry</div>
                            <div class="text-14 lh-14 text-light-1 mt-5">Web Designer</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="swiper-slide-shadow" style={{opacity: "1", transitionDuration: "0ms"}}></div></div>

                <div class="swiper-slide swiper-slide-prev" role="group" aria-label="2 / 3" style={{width:" 410px", zIndex: "1", transform: "translate3d(calc(-13% + 0px), 0px, -200px) rotateZ(-4deg) scale(1)", transitionDuration: "0ms"}}>
                  <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40 shadow-2">
                    <div class="">
                      <h4 class="text-16 fw-500 text-blue-1 mb-20">Hotel Equatorial Melaka</h4>
                      <p class="testimonials__text lh-18 fw-500 text-dark-1">"Our family was traveling via bullet train between cities in Japan with our luggage - the location for this hotel made that so easy. Agoda price was fantastic."</p>

                      <div class="pt-20 mt-28 border-top-light">
                        <div class="row x-gap-20 y-gap-20 items-center">
                          <div class="col-auto">
                            <img src="assests/img/avatars/1.png" alt="image"/>
                          </div>

                          <div class="col-auto">
                            <div class="text-15 fw-500 lh-14">Courtney Henry</div>
                            <div class="text-14 lh-14 text-light-1 mt-5">Web Designer</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="swiper-slide-shadow" style={{opacity: "1", transitionDuration: "0ms"}}></div></div>

                <div class="swiper-slide swiper-slide-visible swiper-slide-active" role="group" aria-label="3 / 3" style={{width:" 410px", zIndex: "1", transform: "translate3d(calc(-13% + 0px), 0px, -200px) rotateZ(-4deg) scale(1)", transitionDuration: "0ms"}}>
                  <div class="testimonials -type-1 bg-white rounded-4 pt-40 pb-30 px-40 shadow-2">
                    <div class="">
                      <h4 class="text-16 fw-500 text-blue-1 mb-20">Hotel Equatorial Melaka</h4>
                      <p class="testimonials__text lh-18 fw-500 text-dark-1">"Our family was traveling via bullet train between cities in Japan with our luggage - the location for this hotel made that so easy. Agoda price was fantastic."</p>

                      <div class="pt-20 mt-28 border-top-light">
                        <div class="row x-gap-20 y-gap-20 items-center">
                          <div class="col-auto">
                            <img src="assets/img/avatars/1.png" alt="image"/>
                          </div>

                          <div class="col-auto">
                            <div class="text-15 fw-500 lh-14">Courtney Henry</div>
                            <div class="text-14 lh-14 text-light-1 mt-5">Web Designer</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="swiper-slide-shadow" style={{opacity: "1", transitionDuration: "0ms"}}></div></div>

              </div>


              <div class="d-flex x-gap-15 items-center justify-center pt-30">
                <div class="col-auto">
                  <button class="d-flex items-center text-24 arrow-left-hover text-white js-prev" tabindex="0" aria-label="Previous slide" aria-controls="swiper-wrapper-d2d0ff75bc10954b2" aria-disabled="false">
                    <i class="icon icon-arrow-left"></i>
                  </button>
                </div>

                <div class="col-auto">
                  <div class="pagination -dots text-white-50 js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><div class="pagination__item" tabindex="0" role="button" aria-label="Go to slide 1"></div><div class="pagination__item" tabindex="0" role="button" aria-label="Go to slide 2"></div><div class="pagination__item is-active" tabindex="0" role="button" aria-label="Go to slide 3" aria-current="true"></div></div>
                </div>

                <div class="col-auto">
                  <button class="d-flex items-center text-24 arrow-right-hover text-white js-next swiper-button-disabled" tabindex="-1" aria-label="Next slide" aria-controls="swiper-wrapper-d2d0ff75bc10954b2" aria-disabled="true" disabled="">
                    <i class="icon icon-arrow-right"></i>
                  </button>
                </div>
              </div>

            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
          </div>
        </div>

       
        
      </div>
    </section>
    
    </>
  )
}

export default Testimonial