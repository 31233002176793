import React from 'react'
import { Link } from 'react-router-dom';

const PopularCruise = () => {
  return (
    <>
    
    <section className="layout-pb-md">
      <div className="container">
        <div data-anim="slide-up delay-1" className="row y-gap-20 justify-between items-end">
          <div className="col-auto">
            <div className="sectionTitle -md">
              <h2 className="sectionTitle__title">Popular Cruise</h2>
              <p className=" sectionTitle__text mt-5 sm:mt-0">These popular destinations have a lot to offer</p>
            </div>
          </div>

          <div className="col-auto md:d-none">

            <a href="https://truxcargo.com/" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
              View All Destinations <div className="icon-arrow-top-right ml-15"></div>
            </a>

          </div>
        </div>

        <div className="relative pt-40 sm:pt-20 js-section-slider" data-gap="30" data-scrollbar data-slider-cols="base-2 xl-4 lg-3 md-2 sm-2 base-1" data-anim="slide-up delay-2">
          <div className="swiper-wrapper">

            <div className="swiper-slide">

              <a href="#" className="citiesCard -type-1 d-block rounded-4 ">
                <div className="citiesCard__image ratio ratio-3:4">
                  <img src="assets/img/destinations/1.jpg" alt="image" className="js-lazy"/>
                </div>

                <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                  <div className="citiesCard__bg"></div>

                  <div className="citiesCard__top">
                    <div className="text-14 text-white">14 Hotel - 22 Cars - 18 Tours - 95 Activity</div>
                  </div>

                  <div className="citiesCard__bottom">
                    <h4 className="text-26 md:text-20 lh-13 text-white mb-20">New York</h4>
                    <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">Discover</button>
                  </div>
                </div>
              </a>

            </div>

            <div className="swiper-slide">

              <a href="#" className="citiesCard -type-1 d-block rounded-4 ">
                <div className="citiesCard__image ratio ratio-3:4">
                  <img src="assets/img/destinations/2.jpg" alt="image" className="js-lazy"/>
                </div>

                <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                  <div className="citiesCard__bg"></div>

                  <div className="citiesCard__top">
                    <div className="text-14 text-white">14 Hotel - 22 Cars - 18 Tours - 95 Activity</div>
                  </div>

                  <div className="citiesCard__bottom">
                    <h4 className="text-26 md:text-20 lh-13 text-white mb-20">London</h4>
                    <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">Discover</button>
                  </div>
                </div>
              </a>

            </div>

            <div className="swiper-slide">

              <a href="#" className="citiesCard -type-1 d-block rounded-4 ">
                <div className="citiesCard__image ratio ratio-3:4">
                  <img src="assets/img/destinations/3.jpg"  alt="image" className="js-lazy"/>
                </div>

                <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                  <div className="citiesCard__bg"></div>

                  <div className="citiesCard__top">
                    <div className="text-14 text-white">14 Hotel - 22 Cars - 18 Tours - 95 Activity</div>
                  </div>

                  <div className="citiesCard__bottom">
                    <h4 className="text-26 md:text-20 lh-13 text-white mb-20">Barcelona</h4>
                    <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">Discover</button>
                  </div>
                </div>
              </a>

            </div>

            <div className="swiper-slide">

              <a href="#" className="citiesCard -type-1 d-block rounded-4 ">
                <div className="citiesCard__image ratio ratio-3:4">
                  <img src="assets/img/destinations/4.jpg" alt="image" className="js-lazy"/>
                </div>

                <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                  <div className="citiesCard__bg"></div>

                  <div className="citiesCard__top">
                    <div className="text-14 text-white">14 Hotel - 22 Cars - 18 Tours - 95 Activity</div>
                  </div>

                  <div className="citiesCard__bottom">
                    <h4 className="text-26 md:text-20 lh-13 text-white mb-20">Sydney</h4>
                    <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">Discover</button>
                  </div>
                </div>
              </a>

            </div>
            <div className="swiper-slide">

              <a href="#" className="citiesCard -type-1 d-block rounded-4 ">
                <div className="citiesCard__image ratio ratio-3:4">
                  <img src="assets/img/destinations/5.jpg" alt="image" className="js-lazy"/>
                </div>

                <div className="citiesCard__content d-flex flex-column justify-between text-center pt-30 pb-20 px-20">
                  <div className="citiesCard__bg"></div>

                  <div className="citiesCard__top">
                    <div className="text-14 text-white">14 Hotel - 22 Cars - 18 Tours - 95 Activity</div>
                  </div>

                  <div className="citiesCard__bottom">
                    <h4 className="text-26 md:text-20 lh-13 text-white mb-20">Rome</h4>
                    <button className="button col-12 h-60 -blue-1 bg-white text-dark-1">Discover</button>
                  </div>
                </div>
              </a>

            </div>

           

          </div>


          <button className="section-slider-nav -prev flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-prev">
            <i className="icon icon-chevron-left text-12"></i>
          </button>

          <button className="section-slider-nav -next flex-center button -blue-1 bg-white shadow-1 size-40 rounded-full sm:d-none js-next">
            <i className="icon icon-chevron-right text-12"></i>
          </button>


          <div className="slider-scrollbar bg-light-2 mt-40 sm:d-none js-scrollbar"></div>

          <div className="row pt-20 d-none md:d-block">
            <div className="col-auto">
              <div className="d-inline-block">

                <a href="#" className="button -md -blue-1 bg-blue-1-05 text-blue-1">
                  View All Destinations <div className="icon-arrow-top-right ml-15"></div>
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default PopularCruise