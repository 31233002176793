import React from 'react'

const HeroBlog = () => {
  return (
    <>
    <section class="layout-pt-lg layout-pb-md">
      <div data-anim-wrap="" class="container animated">
        <div data-anim-child="slide-up delay-1" class="row justify-center text-center is-in-view">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Get inspiration for your next trip</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">Interdum et malesuada fames</p>
            </div>
          </div>
        </div>

        <div class="blog-grid-1 pt-40">

          <div data-anim-child="slide-up delay-2" class="is-in-view">

            <a href="" class="blogCard -type-3 ">
              <div class="blogCard__image rounded-4">
                <img class="rounded-4 js-lazy loaded" src="assets/img/blog/1.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="blogCard__content px-50 pb-30 lg:px-20 pb-20">
                <h4 class="text-26 lg:text-18 fw-600 lh-16 text-white">10 European ski destinations you should visit this winter</h4>
                <div class="text-15 lh-14 text-white mt-10">April 06, 2022</div>
              </div>
            </a>

          </div>

          <div data-anim-child="slide-up delay-3" class="is-in-view">

            <a href="" class="blogCard -type-3 ">
              <div class="blogCard__image rounded-4">
                <img class="rounded-4 js-lazy loaded" src="assest/img/blog/2.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="blogCard__content px-20 pb-20">
                <h4 class="text-18 fw-500 lh-16 text-white">Where can I go? 5 amazing countries that are open right now</h4>
                <div class="text-15 lh-14 text-white mt-10">April 06, 2022</div>
              </div>
            </a>

          </div>

          <div data-anim-child="slide-up delay-4" class="is-in-view">

            <a href="" class="blogCard -type-3 ">
              <div class="blogCard__image rounded-4">
                <img class="rounded-4 js-lazy loaded" src="assets/img/blog/1.png" alt="image" data-ll-status="loaded"/>
              </div>

              <div class="blogCard__content px-20 pb-20">
                <h4 class="text-18 fw-500 lh-16 text-white">Booking travel during Corona: good advice in an uncertain time</h4>
                <div class="text-15 lh-14 text-white mt-10">April 06, 2022</div>
              </div>
            </a>

          </div>

        </div>
      </div>
    </section>
    
    </>
  )
}

export default HeroBlog