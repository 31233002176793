import React from "react";
import HeroQueryForm from "./HeroQueryForm";

const Navigation = () => {
  return (
    <>
      <header
        data-add-bg="bg-dark-1"
        className="header bg-green js-header"
        data-x="header"
        data-x-toggle="is-menu-opened"
      >
        <div  className="header__container px-30 sm:px-20">
          <div className="row justify-between items-center">
            <div className="col-auto">
              <div className="d-flex items-center">
                <a
                  to="index.html"
                  className="header-logo mr-20"
                  data-x="header-logo"
                  data-x-toggle="is-logo-dark"
                >
                 
                  <img src="assets/img/lightlogo.svg" alt="logo icon"  />
                  <img src="assets/img/darklogo.svg" alt="logo icon"  />
                </a>

               
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex items-center">
              

                <div
                  className="header-menu "
                  data-x="mobile-menu"
                  data-x-toggle="is-menu-active"
                >
                  <div className="mobile-overlay"></div>

                  <div className="header-menu__content">
                    <div className="mobile-bg js-mobile-bg"></div>

                    <div className="menu js-navList">
                      <ul className="menu__nav text-white -is-active">
                        <li>
                          <a to="contact.html">Home</a>
                        </li>
                        <li>
                          <a to="contact.html">Aboutus</a>
                        </li>
                        <li>
                          <a to="contact.html">Contact</a>
                        </li>
                        <li>
                          <a to="contact.html">Faqs</a>
                        </li>
                        <li>
                          <a to="contact.html">Blogs</a>
                        </li>
                      </ul>
                    </div>

                    <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer"></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex items-center">
                <div className="d-flex items-center ml-20 is-menu-opened-hide md:d-none">
                  <a to="login.html" className="text-white">
                    info@lightworldtravel.com
                  </a>
                  <a href="" className="mx-2 text-white">
                    |
                  </a>
                  <a to="login.html" className="text-white">
                    +91- 8447275988
                  </a>
                  {/* <a
                    to="signup.html"
                    className="button px-30 fw-400 text-14 border-white -outline-white h-50 text-white ml-20"
                  >
                    Book Package
                  </a> */}
                    <img src="assets/img/flag.svg" alt="" srcset="" style={{width: "50px", marginLeft:"10px"}} />


                </div>

                <div
                  className="d-none xl:d-flex x-gap-20 items-center pl-30 text-white"
                  data-x="header-mobile-icons"
                  data-x-toggle="text-white"
                >
                  
                  <div>
                    <button
                      className="d-flex items-center icon-menu text-inherit text-20"
                      data-x-click="html, header, header-logo, header-mobile-icons, mobile-menu"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section data-anim-wrap className="masthead -type-1 z-5">
        <div className="masthead__bg">
          <img
            src="assets/img/masthead/1/bg-2.jpg"
            alt="image"
            className="js-lazy"
          />
        </div>
        <HeroQueryForm/>
       
      </section>
    </>
  );
};

export default Navigation;
