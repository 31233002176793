import React from 'react'

const Newletter = () => {
  return (
    <>
    <section data-anim="slide-up delay-1" class=" layout-pb-md is-in-view">
      <div class="container">
        <div class="row ml-0 mr-0 items-center justify-between">
          <div class="col-xl-5 px-0">
            <img class="col-12 h-400" src="assets/img/newsletter/1.png" alt="image"/>
          </div>

          <div class="col px-0">
            <div class="d-flex justify-center flex-column h-400 px-80 py-40 md:px-30 bg-light-2">
              <div class="icon-newsletter text-60 sm:text-40 text-dark-1"></div>
              <h2 class="text-30 sm:text-24 lh-15 mt-20">Your Travel Journey Starts Here</h2>
              <p class="text-dark-1 mt-5">Sign up and we'll send the best deals to you</p>

              <div class="single-field -w-410 d-flex x-gap-10 flex-wrap y-gap-20 pt-30">
                <div class="col-auto">
                  <input class="col-12 bg-white h-60" type="text" placeholder="Your Email"/>
                </div>

                <div class="col-auto">
                  <button class="button -md h-60 -blue-1 bg-yellow-1 text-dark-1">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    </>
  )
}

export default Newletter