import React from 'react'

const Whylightworld = () => {
  return (
    <>
     <>
    <section class=" layout-pb-lg">
      <div data-anim-wrap="" class="container animated">
      <div class="row justify-center text-center">
          <div class="col-auto">
            <div class="sectionTitle -md">
              <h2 class="sectionTitle__title">Why Choose Us</h2>
              <p class=" sectionTitle__text mt-5 sm:mt-0">These popular destinations have a lot to offer</p>
            </div>
          </div>
        </div>
        <div class="row y-gap-20 justify-between mt-3">

          <div data-anim-child="slide-up delay-1" class="col-lg-3 col-sm-6 is-in-view">

            <div class="featureIcon -type-1 ">
              <div class="d-flex justify-center">
                <img src="assets/img/featureIcons/1/1.svg" alt="image" class="js-lazy loaded" data-ll-status="loaded"/>
              </div>

              <div class="text-center mt-30">
                <h4 class="text-18 fw-500">Best Price Guarantee</h4>
                <p class="text-15 mt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>

          </div>

          <div data-anim-child="slide-up delay-2" class="col-lg-3 col-sm-6 is-in-view">

            <div class="featureIcon -type-1 ">
              <div class="d-flex justify-center">
                <img src="assets/img/featureIcons/1/2.svg" alt="image" class="js-lazy loaded" data-ll-status="loaded"/>
              </div>

              <div class="text-center mt-30">
                <h4 class="text-18 fw-500">Easy &amp; Quick Booking</h4>
                <p class="text-15 mt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>

          </div>

          <div data-anim-child="slide-up delay-3" class="col-lg-3 col-sm-6 is-in-view">

            <div class="featureIcon -type-1 ">
              <div class="d-flex justify-center">
                <img src="assets/img/featureIcons/1/3.svg" alt="image" class="js-lazy loaded" data-ll-status="loaded"/>
              </div>

              <div class="text-center mt-30">
                <h4 class="text-18 fw-500">Customer Care 24/7</h4>
                <p class="text-15 mt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
    
    </>
    
    </>
  )
}

export default Whylightworld