import "./App.css";
import PopularCruise from "./components/PopularCruise.js";
import Navigation from "./components/Navigation";
import HeroDiscount from "./components/HeroDiscount";
import Newletter from "./components/Newletter";
import Whylightworld from "./components/Whylightworld.js";
import Footer from "./components/Footer.js";
import HeroBlog from "./components/HeroBlog.js";
import Testimonial from "./components/Testimonial.js";
import PopularDestination from "./components/PopularDestination.js";

const App = () => {
  return (
    <>
      <main>
        <Navigation />

        <HeroDiscount />
        <PopularCruise />
        <Whylightworld/>
        <PopularDestination/>
        <Testimonial/>
        <HeroBlog/>
        <Newletter />
        <Footer/>
      </main>
    </>
  );
};

export default App;
