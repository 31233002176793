import React from 'react'

const HeroQueryForm = () => {
  return (
   <>
    <div className="container">
          <div className="row justify-center">
            <div className="col-auto">
              <div className="text-center">
                <h1
                  data-anim-child="slide-up delay-4"
                  className="text-60 lg:text-40 md:text-30 text-white"
                >
                  Booking Your Next Place To Enjoy
                </h1>
                <p
                  data-anim-child="slide-up delay-5"
                  className="text-white mt-6 md:mt-10"
                >
                  Discover amzaing places at exclusive deals
                </p>
              </div>

              <div
                data-anim-child="slide-up delay-6"
                className="tabs -underline mt-60 js-tabs"
              >
                <div className="tabs__controls d-flex x-gap-30 y-gap-20 justify-center sm:justify-start js-tabs-controls">
                  <div className="">
                    <small
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button "
                      
                    >
                      Hotel
                    </small>
                  </div>

                  <div className="">
                    <small
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button "
                      
                    >
                      Tour
                    </small>
                  </div>

                  <div className="">
                    <small
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button "
                      
                    >
                      Activity
                    </small>
                  </div>

                  <div className="">
                    <small
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button "
                     
                    >
                      Holiday Plan
                    </small>
                  </div>

           
                  <div className="">
                    <small
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button "
                      
                    >
                      Cruise
                    </small>
                  </div>

                  <div className="">
                    <small
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button "
                      
                    >
                      Flights
                    </small>
                  </div>
                </div>

                <div className="mainSearch bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100 mt-3">
                      <div className="button-grid items-center">
                        <div className="searchMenu-loc px-20 lg:py-20 lg:px-0">
                          <div data-x-dd-click="searchMenu-loc">
                            <h4 className="text-15 fw-500 ls-2 lh-16">
                              Location
                            </h4>

                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <input
                                type="search"
                                placeholder="Where are you going?"
                                className="js-search js-dd-focus"
                              />
                            </div>
                          </div>

                          
                        </div>

                        <div className="searchMenu-date px-20 lg:py-20 lg:px-0 js-form-dd js-calendar js-calendar-el">
                          <div data-x-dd-click="searchMenu-date">
                            <h4 className="text-15 fw-500 ls-2 lh-16">
                              Check in - Check out
                            </h4>

                            <div className="capitalize text-15 text-light-1 ls-2 lh-16">
                              <span className="js-first-date">Wed 2 Mar</span>-
                              <span className="js-last-date">Fri 11 Apr</span>
                            </div>
                          </div>

                          <div
                            className="searchMenu-date__field shadow-2"
                            data-x-dd="searchMenu-date"
                            data-x-dd-toggle="-is-active"
                          >
                            <div className="bg-white px-20 py-30 rounded-4">
                              <div className="elCalendar js-calendar-el-calendar"></div>
                            </div>
                          </div>
                        </div>

                        <div className="searchMenu-guests px-20 lg:py-20 lg:px-0">
                          <div data-x-dd-click="searchMenu-guests">
                            <h4 className="text-15 fw-500 ls-2 lh-16">Your Name</h4>

                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <input
                                type="text"
                                placeholder="Enter Name"
                                className="js-search js-dd-focus"
                              />
                            </div>
                          </div>

                         
                        </div>
                        <div className="searchMenu-guests px-20 lg:py-20 lg:px-0">
                          <div data-x-dd-click="searchMenu-guests">
                            <h4 className="text-15 fw-500 ls-2 lh-16">Contact Number</h4>

                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <input
                                type="tel"
                                placeholder="Enter Contact Number"
                                className="js-search js-dd-focus"
                              />
                            </div>
                          </div>

                         
                        </div>
           

                        <div className="button-item">
                          <button className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white">
                            
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
   
   </>
  )
}

export default HeroQueryForm